import { useEffect } from 'react';
import S from './index.module.less';
import userInfoStore from '@/stores/userInfo';
import router from 'next/router';

function Highlight({ checkLogin }: { checkLogin: any }) {
  return (
    <div className={S.highlight}>
      <div className={S.content}>
        <div className={S.top}>
          <div className={S.topText}>专家级AI，精准解答每一个问题</div>
          <div className={S.bottomText}>集结500+ AI专家，覆盖多领域，提供实时、精准的专业回答</div>
        </div>
        <div className={S.bottom}>
          <div className={S.card_item}>
            <div className={S.card1} onClick={checkLogin}>
              <div className={S.left}>
                <p>精准专业的答案</p>
                <p>您的每一个问题，我们都从专业的知识中获取答案，确保准确性和专业性。</p>
                <button>立即对话</button>
              </div>
              <div className={S.pic}>
                <img
                  src="https://img.changwenai.com/static/images/changwen-icon/1.png"
                  alt=""
                />
              </div>
            </div>
            <div className={S.card2} onClick={checkLogin}>
              <div className={S.top}>
                <p>知识实时更新</p>
                <p>
                  我们的专家智库会根据最新研究和市场动态实时更新，为您提供最前沿的专业知识和解决方案。
                </p>
                <button>立即对话</button>
              </div>
              <div className={S.pic}>
                <img
                  src="https://img.changwenai.com/static/images/changwen-icon/2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className={S.card_item}>
            <div className={S.card2} onClick={checkLogin}>
              <div className={S.top}>
                <p>全领域覆盖</p>
                <p>
                  从财经、科技到医疗、教育，无论您的问题属于哪个行业，「畅问AI」都能提供专业的解答。
                </p>
                <button>立即对话</button>
              </div>
              <div className={S.pic}>
                <img
                  src="https://img.changwenai.com/static/images/changwen-icon/3.png"
                  alt=""
                />
              </div>
            </div>
            <div className={S.card1} onClick={checkLogin}>
              <div className={S.left}>
                <p>多平台发布使用</p>
                <p>
                  支持任意机器人在微信、钉钉、网页中嵌入使用，灵活发布使用方式让专家智能随时随地为您服务。
                </p>
                <button>立即对话</button>
              </div>
              <div className={S.pic}>
                <img
                className={S.pic4}
                  src="https://img.changwenai.com/static/images/changwen-icon/4.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Highlight;
