
import S from './index.module.less';
import React, { useEffect, useRef, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import Image from 'next/image';
import userInfoStore from '@/stores/userInfo';
import router from 'next/router';

function ProductUseProcess({ checkLogin }: { checkLogin: any }) {


  return (
    <div className={S.all_box}>
      <div className={S.common_box}>
        <div>
          <div className={S.common_h_title}>产品使用流程</div>
          <div className={S.common_title_text}>轻松四步，解锁智能专家咨询</div>


          <div className={S.content_box}>
            <div className={S.content_item_box} onClick={checkLogin}>
              <div className={S.content_fl_img_box}>
                <Image className={S.content_fl_img} width={92} height={72} src='https://img.changwenai.com/static/images/changwen-icon/01.png' alt="blur bg" />
              </div>
              <div className={S.content_fl_box}>
                <div className={S.content_fl_title}>1.搜索/查找机器人</div>
                <div className={S.content_fl_text}>搜索/直接找到你需要的机器人</div>
                <Image className={S.content_fl_img} width={218} height={100} src='https://img.changwenai.com/static/images/changwen-icon/buzhou1.png' alt="blur bg" />

              </div>
            </div>


            <div className={S.right_arrow}>
              <Image className={S.content_fl_img} width={30} height={26} src='https://img.changwenai.com/static/images/changwen-icon/rightArrow.svg' alt="blur bg" />
            </div>


            <div className={S.content_item_box} onClick={checkLogin}>
              <div className={S.content_fl_img_box}>
                <Image className={S.content_fl_img} width={92} height={72} src='https://img.changwenai.com/static/images/changwen-icon/02.png' alt="blur bg" />
              </div>
              <div className={S.content_fl_box}>
                <div className={S.content_fl_title}>2.描述你的问题</div>
                <div className={S.content_fl_text}>对专家直接描述问题提问</div>
                <Image className={S.content_fl_img} width={218} height={100} src='https://img.changwenai.com/static/images/changwen-icon/buzhou2.png' alt="blur bg" />
              </div>
            </div>
            <div className={S.right_arrow}>
              <Image className={S.content_fl_img} width={30} height={26} src='https://img.changwenai.com/static/images/changwen-icon/rightArrow.svg' alt="blur bg" />
            </div>
            <div className={S.content_item_box} onClick={checkLogin}>
              <div className={S.content_fl_img_box}>
                <Image className={S.content_fl_img} width={92} height={72} src='https://img.changwenai.com/static/images/changwen-icon/03.png' alt="blur bg" />
              </div>
              <div className={S.content_fl_box}>
                <div className={S.content_fl_title}>3.获取答案</div>
                <div className={S.content_fl_text}>即刻生成精确答案</div>
                <Image className={S.content_fl_img} width={218} height={100} src='https://img.changwenai.com/static/images/changwen-icon/buzhou3.png' alt="blur bg" />
              </div>
            </div>
            <div className={S.right_arrow}>
              <Image className={S.content_fl_img} width={30} height={26} src='https://img.changwenai.com/static/images/changwen-icon/rightArrow.svg' alt="blur bg" />
            </div>
            <div className={S.content_item_box} onClick={checkLogin}>
              <div className={S.content_fl_img_box}>
                <Image className={S.content_fl_img} width={92} height={72} src='https://img.changwenai.com/static/images/changwen-icon/04.svg' alt="blur bg" />
              </div>
              <div className={S.content_fl_box}>
                <div className={S.content_fl_title}>4.分享与发布</div>
                <div className={S.content_fl_text}>分享答案/机器人给你的朋友用</div>
                <Image className={S.content_fl_img} width={218} height={100} src='https://img.changwenai.com/static/images/changwen-icon/buzhou4.png' alt="blur bg" />
              </div>
            </div>
          </div>
          <div className={S.content_footer}>
            <button className={S.common_btn_box} onClick={checkLogin}>
              <span className={S.common_btn_text} >立即对话</span></button>
          </div>
        </div>


      </div>
    </div>

  );
}

export default ProductUseProcess;
