// TKD组件用于设置页面的标题、关键字和描述。
import { defaultTKD } from '@/configs/common';
import Head from 'next/head';

export default function TKD({
  t,
  k,
  d,
  pageName,
}: {
  t?: string;
  k?: string;
  d?: string;
  pageName?: string;
}) {
  return (
    <Head>
      <title>{t || (pageName ? `${pageName} - 畅问AI` : defaultTKD.t)}</title>
      <meta name="keywords" content={k || (pageName ? `${pageName},畅问AI` : defaultTKD.k)} />
      <meta name="description" content={d || (pageName ? `畅问AI-${pageName}` : defaultTKD.d)} />
    </Head>
  );
}
