import userInfoStore from '@/stores/userInfo';
import S from './index.module.less';
import { useEffect } from 'react';
import React, { useState } from 'react';
import router from 'next/router';

function Modelshow({ checkLogin }: { checkLogin: any }) {
  const modelList = [
    {
      label: '百度—文心一言',
      img: 'https://img.changwenai.com/static/images/changwen-icon/baiduwenxin.png',
    },
    {
      label: '月之暗面—KIMI',
      img: 'https://img.changwenai.com/static/images/changwen-icon/kimi.png',
    },
    {
      label: '清华智谱',
      img: 'https://img.changwenai.com/static/images/changwen-icon/qinghuazhipu.png',
    },
    {
      label: '字节-云雀',
      img: 'https://img.changwenai.com/static/images/changwen-icon/zijie.png',
    },
    {
      label: '科大讯飞—星火',
      img: 'https://img.changwenai.com/static/images/changwen-icon/xunfei.png',
    },
    {
      label: '阿里-通义千问',
      img: 'https://img.changwenai.com/static/images/changwen-icon/ali.png',
    },
    {
      label: 'MiniMax-ABAB',
      img: 'https://img.changwenai.com/static/images/changwen-icon/minimax.png',
    },
    {
      label: '商汤-日日新',
      img: 'https://img.changwenai.com/static/images/changwen-icon/shangtang.png',
    },
    {
      label: '百川大模型',
      img: 'https://img.changwenai.com/static/images/changwen-icon/baichuan.png',
    },
  ];
  const [hoveredIndex, setHoveredIndex] = useState(4); // 默认第五个li被hover

  const handleMouseEnter = (index:number) => {
    setHoveredIndex(index);
  };

//   const handleMouseLeave = () => {
//     setHoveredIndex(4); // 当鼠标离开时，重置为第五个li
//   };
  useEffect(()=>{
    //第5个li元素有边框效果
    const liList = document.querySelectorAll('li');
    liList[4].style.border = '1px solid  #3D70F7';
    //第5个li元素有一定的样式
    liList[4].style.borderRadius = '16px';
    liList[4].style.boxShadow = '-4px 8px 30px 0px rgba(175, 192, 238, 0.50)';
    liList[4].style.display = 'flex';
    liList[4].style.alignItems = 'center';
    liList[4].style.justifyContent="flex-start";
    liList[4].style.padding = '8px 24px';
    liList[4].style.gap = '8px ';
    liList[4].style.cursor = 'pointer';
    // liList[4].style.transition = ' all 0.5s ease-in-out';
    liList[4].style.background = ' #EBF0FE';
    // liList[4].style.height = ' 56px';
    
    // border: 2px solid  #3D70F7;
    // background: #EBF0FE;
    // box-shadow: -4px 8px 30px 0px rgba(175, 192, 238, 0.50);
    // display: flex;
    // padding: 16px 24px;
    // justify-content: flex-start;
    // align-items: center;
    // gap: 8px;
    // cursor: pointer;
    // border-radius: 16px;
    // transition: all 0.5s ease-in-out;
    

  },[]);

  return (
    <div className={S.modelshow}>
      <div className={S.content}>
        <div className={S.blur}></div>
        <div className={S.blur1}></div>
        <div className={S.left}>
          <div className={S.text}>
            <div className={S.title}>
              <p>一个平台</p>
              <p>接入全球大型语言模型</p>
            </div>
            <p>
              专专集合大模型+知识库+插件 搭建行业专家，快速精准解决问题。专专集合大模型+知识库+插件
              。
            </p>
          </div>
          <button onClick={checkLogin}>立即对话</button>
        </div>
        <div className={S.right}>
          <ul onClick={checkLogin}>
            {modelList.map((item,index) => {
              return (
                <>
                  <li key={index}   
          onMouseEnter={() => handleMouseEnter(index)}
        //   onMouseLeave={handleMouseLeave}
          style={{
            border: index === hoveredIndex ? '1px solid #3D70F7' : 'none',
            borderRadius: index === hoveredIndex ?"16px":"null",
          
            background: index === hoveredIndex ?" #EBF0FE":"",
            boxShadow:index === hoveredIndex ? "-4px 8px 30px 0px rgba(175, 192, 238, 0.50)":"",
            display:index === hoveredIndex ? "flex":"",
            padding:index === hoveredIndex ? "8px 24px":"",
            justifyContent:index === hoveredIndex ? "flex-start":"",
            alignItems: index === hoveredIndex ?"center":"",
            gap: index === hoveredIndex ?"8px":"",
            cursor: "pointer",
            transform:index === hoveredIndex ?"scale(1.1)":'',
          
            // transition: index === hoveredIndex ?"all 0.5s ease-in-out":"",
          }}>
                    {' '}
                    <img src={item.img} alt="" />
                    <span>{item.label}</span>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Modelshow;
