
import S from './index.module.less';
import { useEffect, useState } from 'react';
import router from 'next/router';
import userInfoStore from '@/stores/userInfo';


function Recommendation({ checkLogin }: { checkLogin: any }) {
  let cardbox = [
    {
      key: '101',
      img: 'https://img.changwenai.com/static/2023/12/11/e7040b/e7040ba1d4fa2b1a35f419b5766864a7.png',
      title: "学霸机器人",
      content: '助您在学业上取得优异成绩',
    },
    {
      key: '416',

      img: 'https://img.changwenai.com/static/2024/01/09/8a3deb/8a3deb88d61f74432ec90b745fe3574a.png',
      title: "高情商回复领导",
      content: '回复领导不再尴尬！',
    },
    {
      key: '774',
      img: 'https://img.changwenai.com/admin-file/1713366419239.png',
      title: "比亚迪",
      content: '我是小迪，你的比亚迪专家顾问，为你提供比亚迪的最新资讯、深度分析和行业洞见。',
    },
    {
      key: '760',
      img: 'https://pic.aikaida.com/static/images/botFace/25.jpg',
      title: "个人理财规划师",
      content: '我是理财小助手魏然，擅长为您量身定制个人理财规划，从保险到投资，全方位保障您的财富安全。',

    },
    {
      key: '375',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2024/01/02/cfe485/cfe485e1cb54fb1aa21c854194b78877.png',
      title: "职称总结",
      content: '评职称工作总结报告',

    },
    {
      key: '843',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://pic.aikaida.com/image_file/qUByQB0t.png',
      title: "公众号文章生成器",
      content: '文思如泉涌，你的私人公众号文章创作伙伴，擅长挖掘话题，生成吸引眼球的文章，让文字在你的公众号里舞动起来！',

    },
    {
      key: '758',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://pic.aikaida.com/image_file/CHhHa9em.jpg',
      title: "流感应对手册",
      content: '我是流感知识的传播者，为你提供专业的预防与治疗建议。',

    },
    {
      key: '105',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/12/f58eb6/f58eb62f0800560dc7e36bdb9cf10809.png',
      title: "文章改写大师",
      content: '为您提供专业的语法检查、拼写纠正以及表达优化建议',

    },
    {
      key: '844',
      img: 'https://pic.aikaida.com/image_file/cVARLa5g.png',
      title: "文案洗稿助手",
      content: '我是文案洗稿助手，您的私人研究顾问，擅长从海量信息中为您提炼关键知识。',

    },
    {
      key: '821',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://pic.aikaida.com/image_file/z1HPLHeQ.jpg',
      title: "标题党制造机",
      content: '我是标题党制造机，擅长用创意标题抓住你的目光，让你的内容瞬间脱颖而出。',

    },
    {
      key: '432',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2024/01/15/966a11/966a11b74e8088d4a403fdd9ed9ffe71.png',
      title: "Python大师",
      content: '编程语言专家，精通Python编程艺术',

    },
    {
      key: '417',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2024/01/09/90f590/90f59001f5802edca29f1214bdbc6f28.png',
      title: "宝宝起名大师",
      content: '宝宝姓名策划专家，为您的宝宝赋予美好寓意',

    },




    {
      key: '84',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/11/fd2cfc/fd2cfcc54b864fd55ee51fd6ac0d212d.png',
      title: "简历润色专家",
      content: '为您提供专业的简历修改建议',

    },
    {
      key: '754',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://pic.aikaida.com/static/images/botFace/105.jpg',
      title: "婚礼管家",
      content: '我是艾米，专业的庆典策划师，擅长打造浪漫难忘的婚礼，从策划到细节，我都为你保驾护航。',

    },
    {
      key: '15',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/11/24/2160ef/2160ef9062f88d2b38ded7d6a45e2cac.png',
      title: "杠精就是杠",
      content: '杠精一枚，不服来战～！',

    },
    {
      key: '11',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/11/23/108737/108737b38b59f3863a7f3a7c6565adc9.jpeg',
      title: "赚钱达人",
      content: '实现财富自由？达成一个小目标？现在，出发！',

    },
    {
      key: '38',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/11/28/dc1729/dc172995801586fb397dde9949b2c119.jpeg',
      title: "小红书爆款标题大师",
      content: '爆款标题交给我，再也不用想到头秃啦！',

    },
    {
      key: '108',
      img: 'https://img.changwenai.com/static/2023/12/11/5a17c5/5a17c5f57442254d9e939990705cd8e0.png',
      title: "知识百科",
      content: '为您提供各种领域的知识查询服务',

    },
    {
      key: '186',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/14/42b939/42b93959ccbfccc1aead63422c0c8838.png',
      title: "今日头条文案大师",
      content: '为您提供量身定制的今日头条文案创作服务',

    },
    {
      key: '23',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/11/24/f9dee8/f9dee8ab60a6b75a4e6acdcfb83aecc8.jpeg',
      title: "工作日报",
      content: '我知道你需要我，一分钟生成工作日报，对话鸭助你不加班！',

    },
    {
      key: '139',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/13/8eb711/8eb711cbd88444bdb5d4d532521b3b78.png',
      title: "论文开题报告",
      content: '助您更好地规划和开展论文研究',

    },



    {
      key: '100',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/12/b039cc/b039ccdf8cd1e224e09adde0d4e4368c.png',
      title: "产品文案大师",
      content: '为您提供专业的产品推广文案',

    },
    {
      key: '185',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/14/312379/31237927dd6a3b16e713fc691f1e6c98.jpeg',
      title: "短视频标题生成器",
      content: '为您生成引人注目的短视频标题',

    },
    {
      key: '44',
      img: 'https://img.changwenai.com/static/2023/11/28/d37b6f/d37b6f1560942979ea412c546d1c36a7.jpeg',
      title: "知乎文章爆款写手",
      content: '帮你写爆款文章',

    },
    {
      key: '249',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/18/cd3840/cd3840a76bd2fc7e5ca9c3395964eaf2.png',
      title: "发言材料创作大师",
      content: '为您提供关于发言材料创作的专业建议',

    },
    {
      key: '762',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://pic.aikaida.com/static/images/botFace/29.jpg',
      title: "心理咨询师",
      content: '心理咨询师专家，用温暖的话语和科学的建议帮助您缓解压力，发现生活中的积极面。',

    },
    {
      key: '68',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/07/bbb24a/bbb24af9797ff2229d8fafa0f54d4b83.png',
      title: "合同纠纷法律专家",
      content: '为您提供合同纠纷法律咨询服务',

    },

  ];
  const [itemId, setItemId] = useState<string>('');
  // useEffect(() => {
  //   if (userInfoStore.isLogged === 'logged') {
  //     if (itemId) {
  //       debugger;
  //       router.push(`/aibot/${itemId}`);
       
  //     } else {
  //       router.push("/");
  //     }

  //   }

  // }, [itemId])


  return (
    <div className={S.recommendation}>

      <div className={S.title}>
        <div className={S.text1}>
          产品超多亮点，为工作赋能
        </div>
        <div className={S.text2}>
          <p>
            探索AI的精彩，轻松发起AI机器人对话，满足你在工作、学习、生活等场景中的需求
          </p>
          <p>
            探索AI的精彩，轻松发起AI机器人对话
          </p>


        </div>

      </div>
      <div className={S.content}>
        <div className={S.box}>
          {cardbox.slice(0,12).map((item) => {
            return <>

              <div className={S.card} key={item.key} onClick={e => checkLogin(`/aibot/${item.key}`)}>

                <div className={S.item}>
                  <div className={S.img}>
                    <img src={item.img} alt="" />
                  </div>
                  <div className={S.text}>

                    <div className={S.title}>
                      {item.title}
                    </div>
                    <div className={S.textContent}>
                      {item.content}
                    </div>
                  </div>


                </div>
              </div>



            </>
          })}


        </div>

      </div>
      <button onClick={() => {

        if (userInfoStore.isLogged !== 'logged') {
          userInfoStore.toggleLoginShow(true);

          return
        }


      }}><span>立即对话</span>
      </button>
    </div>
  );
}

export default Recommendation;
