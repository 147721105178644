import S from './index.module.less';
import React, { useEffect, useRef, useState } from 'react';
import { Rate } from 'antd';
import Image from 'next/image';
import userInfoStore from '@/stores/userInfo';
import router from 'next/router';
function UserCard({ checkLogin }: { checkLogin: any }) {
  return (
    <div className={S.appraise_box} onClick={checkLogin}>
      <p>用户评价</p>
      <div className={S.appraise_score_box}>
        <Rate disabled defaultValue={5} className={S.start_color} />
        <span className={S.score_box}>4.8<span className={S.score_small}>/5</span></span>
      </div>

    </div>
  )
}
function HoverableItem({ title, details, image,checkLogin }: { title: string, details: string, image: string ,checkLogin: any}) {
  const [isHovered, setIsHovered] = useState(false);
  const itemDetailsRef = useRef<HTMLDivElement>(null);

  return (
    <div className={S.item_box}
      style={{
        height: isHovered ? `${128 + itemDetailsRef.current.scrollHeight}px` : '204px',
      }}
      onMouseEnter={() => {

        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);

      }}
      onClick={checkLogin}
    >
      <div className={S.item_top_box}>
        <div className={S.item_head}>
          <Image width={60} height={60} src={image} alt="blur bg" />
          <div className={S.item_head_rig}>
            <p className={S.item_rig_title}>{title}</p>
            <Rate disabled defaultValue={5} className={S.start_color} />
          </div>
        </div>
        <div className={isHovered ? S.item_details_hover : S.item_details} ref={itemDetailsRef}>{details}</div>

      </div>

    </div>
  )
}
function UserEvaluation({ checkLogin }: { checkLogin: any }) {


  return (
    <div className={S.common_box}>
      <div>
        <div className={S.common_h_title}>用户评价</div>
        <div className={S.common_h_text}>
          <p>探索AI的精彩，轻松发起AI机器人对话，满足你在工作、学习、生活等场景中的需求</p>
          <p>探索AI的精彩，轻松发起AI机器人对话</p>
        </div>
        <div className={S.card_box}>
          <div className={S.card_first_line}>
            <UserCard checkLogin={checkLogin}></UserCard>
            <HoverableItem
            checkLogin={checkLogin}
              image="https://img.changwenai.com/static/images/changwen-icon/Ellipse1.svg"
              title="星辰财经"
              details="作为财务分析师，我经常需要快速理解和整理大量的市场报告和财务数据。使用「畅问AI」上传相关文章后，它能迅速总结出关键数据和趋势，大大节省了我编写报告的时间。现在，我可以在短时间内准备高质量的分析报告，这对于追踪市场动态和做出快速决策至关重要。"
            ></HoverableItem>
            <HoverableItem
            checkLogin={checkLogin}
              image="https://img.changwenai.com/static/images/changwen-icon/Ellipse2.svg"
              title="生物医药家"
              details="我是一名医学研究员，每天都要阅读大量的临床试验报告和医学文献。‘畅问AI’的文献快速阅读功能帮我筛选和总结了最关键的信息，显著提高了我的研究效率。特别是在准备研究报告或更新研究数据时，我能够迅速获得所需的科学证据和统计数据，这对我的工作帮助极大。"
            ></HoverableItem>

          </div>
          <div className={S.card_second_line}>
            <HoverableItem
            checkLogin={checkLogin}
              image="https://img.changwenai.com/static/images/changwen-icon/Ellipse3.svg"
              title="法网精灵"
              details="作为专业律师，每天都需要处理大量复杂的案件文件和法律文书。‘畅问AI’不仅帮助我快速理解和整理案件材料，而且还能精确提取出法律文件中的关键条款和案例先例，使我能够更高效地为客户服务。这项技术显著提高了我的工作效率，也使我在同行中脱颖而出。"
            ></HoverableItem>
            <HoverableItem
            checkLogin={checkLogin}
              image="https://img.changwenai.com/static/images/changwen-icon/Ellipse4.svg"
              title="代码战士"
              details="我是一名软件开发工程师，经常需要快速学习和应用新的编程技术。‘畅问AI’使我能够迅速理解各种技术文档和开发指南，极大地提高了我的学习效率。它不仅帮助我节省了大量的研究和学习时间，而且也使我在技术更新和项目开发上始终保持领先。"
            ></HoverableItem>

          </div>
          <div className={S.card_third_line}>
            <HoverableItem
            checkLogin={checkLogin}
              image="https://img.changwenai.com/static/images/changwen-icon/Ellipse5.svg"
              title="商业先锋"
              details="为市场分析师，了解最新的行业动态和消费趋势是我的日常工作之一。通过使用‘畅问AI’的市场分析报告功能，我能迅速获得深入的行业分析和消费数据，这对于制定有效的市场策略和提升竞争力是非常有价值的。这个工具不仅提高了我的工作效率，还增强了我的报告的准确性和洞察力。"
            ></HoverableItem>
            <HoverableItem
            checkLogin={checkLogin}
              image="https://img.changwenai.com/static/images/changwen-icon/Ellipse6.svg"
              title="知识导师"
              details="我是一名教育专家，经常需要为不同水平的学生准备教材和教学策略。使用「畅问AI」后，我可以快速获取各种教育理论和实践的深入分析，从而更有效地设计课程和教学活动。这个平台不仅帮助我节省了大量的筹备时间，而且通过其精确的知识匹配功能，确保了教学内容的丰富性和适应性。它的智能总结功能特别适合处理大量教育研究资料，让我可以迅速掌握关键概念和趋势，进一步提高教学质量和学生的学习效果。"
            ></HoverableItem>

          </div>

        </div>



      </div>


    </div>
  );
}

export default UserEvaluation;
