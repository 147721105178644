import Typed from 'typed.js';
import S from './index.module.less';
import { useEffect, useRef, useState } from 'react';
import userInfoStore from '@/stores/userInfo';

import { getAIBotInfo } from '@/services/aibot';

// import logo from './../../assets/image/logo.png';

function Feature({ checkLogin }: { checkLogin: any }) {
  const el = useRef(null);

  let card = [
    {
      key: '43',
      img: 'https://img.changwenai.com/static/2023/12/14/ee7f4f/ee7f4f96e878f3a8878722c54afcb175.jpeg',
      title: '短视频开头5秒',
      content: '点亮你的短视频前5秒内容',
    },
    {
      key: '39',

      img: 'https://img.changwenai.com/static/2023/11/28/c4f084/c4f0845cad87d4118158ccda6e557f25.jpeg',
      title: '小红书种草博主',
      content: '一键代写，种草文案交给我',
    },
    {
      key: '47',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/11/28/a897ae/a897ae4376e934dc155c95b6ce5032e9.jpeg',
      title: 'PPT大纲生成',
      content: '一分钟生成PPT大纲',
    },
    {
      key: '80',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/08/7b20f0/7b20f021bc0d359da17f064d025597a3.png',
      title: '述职总结小能手',
      content: '为您定制专业的述职报告',
    },
    {
      key: '28',
      //   img: PICURL + "static/images/introduction/IQ.png",
      img: 'https://img.changwenai.com/static/2023/12/01/ebd9ea/ebd9ea612927cfdd18334b3d796baf82.png',
      title: '演讲稿生成器',
      content: '帮你写各种类型的演讲稿',
    },
    {
      key: '95',
      title: '营销话术专家',
      content: '提供专业营销话术生成服务',
      img: 'https://img.changwenai.com/static/2023/12/12/41a6e4/41a6e4ff7599eda923dc3f999493d649.png',
    },
  ];
  // const [carditem, setCardItem] = useState<{ img: string, title: string, content: string, key: string }[]>(card);
  // const geiBotInfo = () => {
  //   card.map((item) => {

  //     getAIBotInfo({ id: Number(item.key) }).then((info) => {
  //       item.img = info.head_portrait;
  //       item.content = info.brief_introduction;

  //     });
  //   })

  //   setCardItem(card);
  //   console.log(carditem);
  //   console.log(9);
  // }

  // useEffect(() => {
  //   geiBotInfo();

  // }, [])
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        `<span >答疑解惑</span>` +
        "^3000",
      `<span >打工</span>` +
        "^1500",
      `<span >出方案</span>` +
        "^1500",
      `<span >找资料</span>` + "^1500",
      `<span >写文案</span>` + "^1500",
      ],
      typeSpeed: 100,
      loop: true,
      showCursor: false,
      fadeOut: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div className={S.feature}>
      {/* <LoginModal/> */}
      <div className={S.title}>
        <div className={S.left}>
          <div className={S.logo}>
            <img src="https://img.changwenai.com/static/images/changwen-icon/Frame.png" alt="" />
            {/* <div className={S.text}></div> */}
            <img src="https://img.changwenai.com/static/images/changwen-icon/aianswer.png" alt="" />
          </div>

          {/* <Image style={{height: 32, width: 104}} src={'https://img.changwenai.com/static/images/changwen-icon/frame.png'}  alt="blur bg" /> */}
        </div>
        <div className={S.right}>
          <button onClick={checkLogin}>登录/注册</button>
        </div>
      </div>
      <div className={S.content}>
        <div className={S.box1}>
          <div className={S.box1_title}> 
        
            <div className={S.top}  ><div >718 专业AI专家 | 为你</div><div className={S.type} ref={el} id="typed-strings">
              {''}
            </div>
        
            </div>
            <div className={S.bottom}>
            一比一复刻真实专家，是AI胜过真人
            </div>
          </div>
          <div className={S.button}>
          <button onClick={checkLogin}>立即对话</button>
          </div>
         
        </div>
        <div className={S.box2} onClick={checkLogin}>
          <div className={S.box2_left}></div>
          <div className={S.box2_right}>
            <div className={S.sidebar}>
              <div className={S.text}>
                <p>集合</p>
                <p>20+行业</p>
                <p>专业级机器人</p>
              </div>
              <div className={S.side_content}>
                <p>解决金融/互联网/电商/法律/医疗/营销/教育等行业专业问题，满足各种场景需求</p>
              </div>
              <button>立即对话</button>
            </div>
            <div className={S.box2_rightContent}>
              <div className={S.contentLeft}>
                <div className={S.contentLeft_box1}>
                  <p>专业机器人</p>
                  <p>集合大模型+知识库+插件 搭建行业专家，快速精准解决问题。</p>
                </div>
                <div>
                  <img
                    src="https://img.changwenai.com/static/images/changwen-icon/Rectangle4.png"
                    alt=""
                  />
                  <img
                    // src="https://img.changwenai.com/static/images/changwen-icon/pic.png"
                    alt=""
                  />
                  <img
                    // src="https://img.changwenai.com/static/images/changwen-icon/nav.png"
                    alt=""
                  />
                  <img
                    // src="https://img.changwenai.com/static/images/changwen-icon/pic2.png"
                    alt=""
                  />
                </div>
              </div>
              <div className={S.contentRight}>
                <div className={S.right_box}>
                  {card.slice(0, 2).map((card) => {
                    return (
                      <div className={S.card} key={card.key}>
                        <div className={S.pic}>
                          {' '}
                          <img src={card.img} alt="" />
                        </div>
                        <div className={S.card_item}>
                          <p>{card.title}</p>
                          <p>{card.content}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={S.right_box}>
                  {card.slice(2, 4).map((card) => {
                    return (
                      <div className={S.card} key={card.key}>
                        <div className={S.pic}>
                          <img src={card.img} alt="" />
                        </div>
                        <div className={S.card_item}>
                          <p>{card.title}</p>
                          <p>{card.content}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={S.right_box}>
                  {card.slice(4, 6).map((card) => {
                    return (
                      <div className={S.card} key={card.key}>
                        <div className={S.pic}>
                          <img src={card.img} alt="" />
                        </div>
                        <div className={S.card_item}>
                          <p>{card.title}</p>
                          <p>{card.content}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
