import React, { useState, useEffect } from 'react';
import S from './index.module.less';

const CommonBottom = () => {
  const PICURL = 'https://img.ichuangnao.com/'; // 阿里oss 资源域名

  return (
    <>
      <div className={S['index-bottom']}>
        <div className={S['href-box']}>
          <span>友情链接：</span>
          <a href="https://www.58pic.com?from=ichuangnao" target="_blank">千图网</a>
          <a href="https://hisheai.com?from=ichuangnao" target="_blank">千图设计室丨AI助手</a>
          <a href="https://hihaibao.com?from=ichuangnao" target="_blank">千图设计室丨AI海报</a>
          <a href="https://changwenai.com?from=ichuangnao" target="_blank">畅问AI机器人</a>
          <a href="https://ichuangnao.com/" target="_blank">创脑机器人社区</a>

          <a href="https://baoyueai.com/?utm_id=221684" target="_blank">包阅AI阅读</a>
        </div>
        <span>Copyright ©2013-现在 千图网</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">
          沪ICP备10011451号-6
        </a>
        <span>
          ICP证书: 沪B2-20180057
        </span>
        <a
          href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020230506160018000010464131-SAIC_SHOW_310000-8875440eec00498d86ff83093b598981551&signData=MEYCIQCOSPZRY9N6MO4xMoeOS9HYDg4CBdW+s3pF+mJHZ4YXEwIhALwUn3RbwYoD64BvmMhOj76P5KUGnArnyeB31fawU3tS"
          target="_blank">
          <img src={PICURL + 'static/images/common/common2-1.png'} alt="" />
          上海工商
        </a>
        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502008675" target="_blank">
          沪公网安备 31011502008675号
        </a>
        <a href="https://xinyong.yunaq.com/certificate?domain=www.58pic.com&from=label&code=90020" target="_blank">
          <img src={PICURL + 'static/images/common/image-5.png'} alt="" />
        </a>
        <a href="https://www.shjbzx.cn/" target="_blank">
          <img src={PICURL + 'static/images/common/shanghai.png'} alt="" />
        </a>
        <a href="https://www.shjbzx.cn/" target="_blank">
          <img src={PICURL + 'static/images/common/center-cac.png'} alt="" />
        </a>
      </div>
    </>

  );
};
export default CommonBottom;
