
import S from './index.module.less';
import React, { useEffect, useRef, useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

function CommonProblem() {
  function QADetail({ question, answer }: { question: string, answer: string }) {
    const [showDetail, setShowDetail] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState<string | null>(null);
    const handleClick = () => {

      if (currentQuestion === question) {
        setShowDetail(!showDetail);
      } else {
        setCurrentQuestion(question);
        setShowDetail(true);
      }
    };

    return (
      <div className={S.item_box}>
        <p className={`${S.item_q_text} ${currentQuestion === question && showDetail ? S.item_q_text_active : ''}`} onClick={handleClick}>
          <span>{question}</span> {currentQuestion === question && showDetail ? <UpOutlined style={{ fontSize: '10px' }} /> : <DownOutlined style={{ fontSize: '10px' }} />}
        </p>
        {currentQuestion === question && showDetail && <div className={S.item_q_detail}>{answer}</div>}
      </div>
    );
  }

  return (
    <div className={S.common_box}>
      <div>
        <div className={S.common_h_title}>常见问题Q&A</div>

        <div className={S.content_box}>
          <div className={S.common_left_box}>
            <QADetail question="畅问AI是什么？" answer="畅问AI是一个AI专家问答平台，为用户提供精准的行业专家级解答。" />
            <QADetail question=" 我如何使用畅问AI提问？" answer="您只需登录平台，选择您的问题相关领专家域机器人，提交问题，系统即刻提供答案。" />
            <QADetail question="畅问AI可以解答哪些类型的问题？" answer="从商业、科技到医疗、法律，我们的AI专家能覆盖广泛的行业知识。" />
            <QADetail question=" 使用畅问AI需要付费吗？" answer="平台每天赠送2000字免费使用。提供免费和付费两种机器人服务，具体根据问答的复杂度和专业性而定。" />
            <QADetail question="畅问AI的答案准确性如何保证？" answer="我们的答案基于广泛的专家知识库和最新的行业数据，确保高准确性。" />
            <QADetail question="畅问AIVIP有哪些？" answer="我们有3天畅用卡不限次数、月卡、季卡、年卡。全站模型可用：可以使用所有模型进行对话聊天；全站机器人可用：可以使用所有机器人进行对话聊天；多模型PK布局切换：提供了4种常见布局模式：可以按照自己的喜好切换布局模式；与文件对话可用：上传PDF、word、txt文档，并针对文档提问。" />
          </div>
          <div className={S.common_right_box}>
            <QADetail question="      我如何知道回答是否来自真正的专家？" answer="我们的每一个AI专家都基于真实的专家知识库建立，具有确切的行业背景。" />
            <QADetail question=" 如果我对答案不满意，可以获得进一步的帮助吗？" answer="当然，如果对结果不满意，可以继续追问，或者换其他机器人咨询。您也可以请求更多信息或向我们的客服团队反馈，以获得进一步的协助。产品建议反馈：https://58pic-qiye.feishu.cn/share/base/form/shrcn443TvLdJxjYP7fkTOjpRAf" />
            <QADetail question="畅问AI如何选择其AI专家的？" answer="我们基于专业能力、知识深度和行业影响力选择顶尖的行业专家进行模拟。" />
            <QADetail question=" 我可以在哪些地方使用畅问AI的专家机器人？" answer="您可以将畅问AI的任何机器人发布到您的微信、钉钉、网站是使用。" />
            <QADetail question="畅问AI有哪些主要的竞争优势？" answer="畅问AI的主要竞争优势在于其专业性、实时更新的知识库和广泛的行业覆盖。首先，我们的平台聚集了500+顶级AI专家的智慧，覆盖财经、科技、医疗、教育等多个领域，能够为用户提供深度且专业的答案。其次，我们的知识库不断根据最新的研究和市场动态更新，确保用户获得的信息始终是最前沿的。此外，「畅问AI」采用了先进的RAG（可检索式生成网络）技术，调用了市面上论文/书籍等专业知识，使得答案不仅准确，还具有很高的相关性，确保每个用户问题都能得到最精确的回答。这些因素共同构成了「畅问AI」在市场上的独特竞争力。" />
          </div>
        </div>
      </div>


    </div>
  );
}

export default CommonProblem;
